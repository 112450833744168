import { useState, useEffect } from 'react';
import { Plus, Baby, AlertTriangle, Activity, Apple, Target, Pencil, Trash2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { getChildProfiles, deleteChildProfile, type HealthProfile } from '../services/userService';
import { useNavigate } from 'react-router-dom';

export default function HealthProfiles() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState<HealthProfile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      if (!user?.id) return;
      
      try {
        const fetchedProfiles = await getChildProfiles(user.id);
        setProfiles(fetchedProfiles);
      } catch (err) {
        setError('Failed to load health profiles');
        console.error('Error fetching health profiles:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfiles();
  }, [user?.id]);

  const handleDelete = async (profileId: string) => {
    if (!confirm('Are you sure you want to delete this profile? This action cannot be undone.')) {
      return;
    }

    setIsDeleting(profileId);
    try {
      await deleteChildProfile(profileId);
      setProfiles(profiles.filter(profile => profile.id !== profileId));
    } catch (err) {
      setError('Failed to delete profile');
      console.error('Error deleting profile:', err);
    } finally {
      setIsDeleting(null);
    }
  };

  if (isLoading) {
    return <div className="text-center py-4">Loading profiles...</div>;
  }

  if (error) {
    return <div className="text-red-600 py-4">{error}</div>;
  }

  const getRelationshipLabel = (relationship: string) => {
    const labels: Record<string, string> = {
      'self': 'My Profile',
      'child': 'My Child',
      'parent': 'My Parent',
      'grandparent': 'My Grandparent'
    };
    return labels[relationship] || relationship;
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Health Profiles</h2>
      
      <div className="space-y-4">
        {profiles.map((profile) => (
          <div key={profile.id} className="border border-gray-200 rounded-lg p-4">
            <div className="space-y-4">
              {/* Basic Info with new action buttons */}
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-3 flex-1 min-w-0">
                  <div className="w-10 h-10 bg-emerald-100 rounded-full flex items-center justify-center flex-shrink-0">
                    <Baby className="h-6 w-6 text-emerald-600" />
                  </div>
                  <div className="min-w-0">
                    <h3 className="text-lg font-medium text-gray-900 truncate">{profile.name}</h3>
                    <div className="mt-1 flex items-center gap-2 text-sm text-gray-500 flex-wrap">
                      <span>{getRelationshipLabel(profile.relationship)}</span>
                      <span>•</span>
                      <span>{profile.age} years old</span>
                      <span>•</span>
                      <span className="capitalize">{profile.activity_level || 'No activity level set'}</span>
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex items-center gap-2 flex-shrink-0 ml-4">
                  <button
                    onClick={() => navigate(`/child-health-profile/edit/${profile.id}`)}
                    className="p-2 text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 rounded-full transition-colors"
                    title="Edit profile"
                  >
                    <Pencil className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(profile.id)}
                    disabled={isDeleting === profile.id}
                    className={`p-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors
                      ${isDeleting === profile.id ? 'opacity-50 cursor-not-allowed' : ''}`}
                    title="Delete profile"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </div>

              {/* Health Details */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-3">
                {/* Allergens */}
                <div className="flex items-start gap-2">
                  <AlertTriangle className="h-5 w-5 text-amber-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">Allergen Alerts</h4>
                    <div className="mt-1 flex flex-wrap gap-1">
                      {profile.allergen_alerts && profile.allergen_alerts.length > 0 ? (
                        profile.allergen_alerts.map((allergen: string, index: number) => (
                          <span 
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-amber-100 text-amber-800"
                          >
                            {allergen}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-gray-500">No allergens listed</span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Dietary Restrictions */}
                <div className="flex items-start gap-2">
                  <Apple className="h-5 w-5 text-emerald-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">Dietary Restrictions</h4>
                    <div className="mt-1 flex flex-wrap gap-1">
                      {profile.dietary_restrictions && profile.dietary_restrictions.length > 0 ? (
                        profile.dietary_restrictions.map((restriction: string, index: number) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-emerald-100 text-emerald-800"
                          >
                            {restriction}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-gray-500">No restrictions listed</span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Health Concerns */}
                <div className="flex items-start gap-2">
                  <Activity className="h-5 w-5 text-blue-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">Health Concerns</h4>
                    <div className="mt-1 flex flex-wrap gap-1">
                      {profile.health_concerns && profile.health_concerns.length > 0 ? (
                        profile.health_concerns.map((concern: string, index: number) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
                          >
                            {concern}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-gray-500">No health concerns listed</span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Fitness Goals */}
                <div className="flex items-start gap-2">
                  <Target className="h-5 w-5 text-purple-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">Fitness Goals</h4>
                    <div className="mt-1 flex flex-wrap gap-1">
                      {profile.fitness_goals && profile.fitness_goals.length > 0 ? (
                        profile.fitness_goals.map((goal: string, index: number) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800"
                          >
                            {goal}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-gray-500">No fitness goals listed</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <button
          onClick={() => navigate('/child-health-profile')}
          className="flex items-center justify-center w-full py-2 px-4 border-2 border-dashed border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Health Profile
        </button>
      </div>
    </div>
  );
} 