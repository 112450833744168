import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './hooks/useAuth';
import Home from './pages/Home';
import PricingPage from './pages/Pricing';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import ChildHealthProfile from './pages/ChildHealthProfile';
import FAQPage from './pages/FAQ';
import ContactPage from './pages/Contact';
import Settings from './pages/Settings';
import Scanner from './pages/Scanner';
import { Analytics } from './components/Analytics';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import BlogList from './pages/BlogList';
import BlogPost from './pages/BlogPost';
import ScannerPage from './pages/Scanner';

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return user ? <>{children}</> : <Navigate to="/login" />;
}

const websiteSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "https://barcodebites.com",
  "potentialAction": {
    "@type": "SearchAction",
    "target": {
      "@type": "EntryPoint",
      "urlTemplate": "https://barcodebites.com/search?q={search_term_string}"
    },
    "query-input": "required name=search_term_string"
  }
};

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <Analytics />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/child-health-profile" element={<ChildHealthProfile />} />
          <Route path="/child-health-profile/edit/:id" element={<ChildHealthProfile />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route path="/scan" element={<Scanner />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/scanner" element={<ScannerPage />} />
        </Routes>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(websiteSchema)}
          </script>
        </Helmet>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;