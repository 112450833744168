import { useState, useEffect, useRef, TouchEvent, useCallback } from 'react';
import { AlertTriangle } from 'lucide-react';
import { foodItems } from '../data/foodData';

const rotatingWords = ["you.", "your child.", "your mom."];

export default function Hero() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef<number>(0);
  const touchEndX = useRef<number>(0);
  
  // Add new state for typewriter effect
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const nextWord = useCallback(() => {
    setIsTransitioning(true);
    setIsDeleting(false);
    setWordIndex((prev) => (prev + 1) % rotatingWords.length);
    setText('');
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 200);
  }, []);

  // Add typewriter effect
  useEffect(() => {
    if (isTransitioning) return;

    const currentWord = rotatingWords[wordIndex];
    let timeout: NodeJS.Timeout;

    if (!isDeleting) {
      if (text === currentWord) {
        timeout = setTimeout(() => {
          setIsDeleting(true);
        }, 2000);
      } else {
        timeout = setTimeout(() => {
          setText(currentWord.slice(0, text.length + 1));
        }, 100 + Math.random() * 40);
      }
    } else {
      if (text === '') {
        nextWord();
      } else {
        timeout = setTimeout(() => {
          setText(text.slice(0, -1));
        }, 50 + Math.random() * 20);
      }
    }

    return () => clearTimeout(timeout);
  }, [text, isDeleting, wordIndex, isTransitioning, nextWord]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % foodItems.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const handleTouchStart = (e: TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchDiff = touchStartX.current - touchEndX.current;
    const minSwipeDistance = 50;

    if (Math.abs(touchDiff) > minSwipeDistance) {
      if (touchDiff > 0) {
        // Swipe left - next image
        setCurrentIndex((prev) => (prev + 1) % foodItems.length);
      } else {
        // Swipe right - previous image
        setCurrentIndex((prev) => (prev - 1 + foodItems.length) % foodItems.length);
      }
    }
  };

  return (
    <div className="relative bg-white overflow-hidden min-h-[85vh]">
      {/* SVG Background Pattern */}
      <div className="absolute inset-0 z-0">
        {/* Dotted Pattern */}
        <svg
          className="absolute inset-0 w-full h-full"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <pattern
            id="dotPattern"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <circle cx="2" cy="2" r="1" fill="#E5E7EB" />
          </pattern>
          <rect width="100%" height="100%" fill="url(#dotPattern)" />
        </svg>

        {/* Gradient Circle */}
        <svg
          className="absolute right-0 top-0 h-full w-1/2 transform translate-x-1/3"
          width="100%"
          height="100%"
          viewBox="0 0 800 800"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin slice"
        >
          <circle cx="400" cy="400" r="400" fill="url(#gradient)" fillOpacity="0.1" />
          <defs>
            <radialGradient
              id="gradient"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(400 400) rotate(90) scale(400)"
            >
              <stop stopColor="#059669" />
              <stop offset="1" stopColor="#059669" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>

      <div className="relative z-10 min-h-[85vh] flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid lg:grid-cols-2 gap-12 items-center mt-12 sm:mt-0">
            {/* Left Column - Text Content */}
            <div className="text-center lg:text-left">
              <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-7xl font-serif tracking-tight">
                <span className="block text-center lg:text-left">
                  A personal nutritionist,{' '}
                </span>
                <span className="block text-center lg:text-left">
                  customized for{' '}
                  <span className="relative inline-block" style={{ minWidth: '280px' }}>
                    <span className="text-emerald-600 absolute left-1/2 lg:left-0 transform -translate-x-1/2 lg:translate-x-0 transition-all duration-200 whitespace-nowrap">
                      {text}
                      <span className={`ml-0.5 ${isTransitioning ? 'opacity-0' : 'animate-blink'}`}>|</span>
                    </span>
                    <span className="invisible">placeholder</span>
                  </span>
                </span>
              </h1>
              <p className="mt-12 text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto lg:mx-0">
                Scan any food to see what's best for your health needs
              </p>
              <div className="mt-10 flex flex-wrap justify-center lg:justify-start gap-4">
                <button 
                  onClick={() => {
                    const element = document.querySelector('section');
                    const offset = 30;
                    const elementPosition = element?.getBoundingClientRect().top ?? 0;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;
                    
                    window.scrollTo({
                      top: offsetPosition,
                      behavior: 'smooth'
                    });
                  }}
                  className="bg-emerald-600 text-white px-10 py-4 rounded-full hover:bg-emerald-700 
                    transition-all text-lg font-medium shadow-lg hover:shadow-xl 
                    hover:scale-105 transform duration-200"
                >
                  Start Scanning
                </button>
              </div>
            </div>

            {/* Right Column - Carousel */}
            <div className="relative aspect-[4/3] overflow-hidden">
              <div 
                className="absolute inset-0 flex transition-all duration-1000 ease-in-out carousel-container"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                {foodItems.map((item, index) => (
                  <div
                    key={index}
                    className={`w-full flex-none relative transition-all duration-1000 ${
                      index === currentIndex
                        ? 'opacity-100 visible'
                        : 'opacity-0 invisible'
                    }`}
                  >
                    {/* Image Container - No Background */}
                    <div className="relative w-full h-full p-10">
                      <img
                        src={item.image}
                        alt={item.name}
                        className={`w-full h-full object-contain transform transition-transform duration-500 ${
                          item.image === '/lunchly.png' 
                            ? 'scale-125 hover:scale-[1.35]'
                            : 'hover:scale-105'
                        }`}
                      />
                      
                      {/* Floating Warning Tags */}
                      {item.tags.map((tag, tagIndex) => (
                        <div
                          key={tagIndex}
                          className="absolute"
                          style={{
                            top: `${tag.position.top}%`,
                            left: `${tag.position.left}%`,
                            transform: 'translate(-50%, -50%)',
                          }}
                        >
                          <div className="bg-red-50/95 backdrop-blur-sm border border-red-200 shadow-lg text-gray-900 px-2 sm:px-4 py-1 sm:py-2 rounded-lg transform hover:scale-110 transition-all duration-300 group hover:bg-red-100">
                            <div className="flex items-center gap-1 sm:gap-1.5 mb-0.5">
                              <AlertTriangle className="h-3 w-3 sm:h-3.5 sm:w-3.5 text-red-500" />
                              <div className="text-xs sm:text-sm font-semibold text-red-700">{tag.label}</div>
                            </div>
                            <div className="text-[10px] sm:text-xs text-red-600">{tag.value}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}