import { analyzeProductForFamily, getAIRecommendationStream as getGeminiStream } from './geminiService';
import { getChildProfiles } from './userService';
import type { Product } from '../types/supabase';
import type { AIRecommendation } from '../types/ai';
import type { HealthProfile } from './userService';

// Simple in-memory cache
const aiCache = new Map<string, {
  recommendation: AIRecommendation;
  timestamp: number;
  userHash: string;
}>();

const CACHE_DURATION = 1000 * 60 * 60; // 1 hour

// Create a hash of user profiles to determine if cache should be invalidated
function createUserHash(childProfiles: HealthProfile[]): string {
  return childProfiles
    .map(p => `${p.id}-${p.updated_at}`)
    .sort()
    .join('|');
}

export async function getAIRecommendation(product: Product, userId?: string): Promise<AIRecommendation> {
  try {
    let childProfiles: HealthProfile[] = [];
    
    if (userId) {
      try {
        childProfiles = await getChildProfiles(userId);
      } catch (error) {
        console.warn('Error fetching child profiles:', error);
      }
    }

    const userHash = createUserHash(childProfiles);
    const cacheKey = `${product.id}-${product.last_updated}`;
    const cached = aiCache.get(cacheKey);

    // Return cached result if valid and user profiles haven't changed
    if (cached && 
        Date.now() - cached.timestamp < CACHE_DURATION &&
        cached.userHash === userHash) {
      return cached.recommendation;
    }

    // Get fresh recommendation
    const recommendation = await analyzeProductForFamily(product, childProfiles);
    
    // Cache the result
    aiCache.set(cacheKey, {
      recommendation,
      timestamp: Date.now(),
      userHash
    });

    return recommendation;
  } catch (error) {
    console.error('Error getting AI recommendation:', error);
    throw error;
  }
}

export async function getAIRecommendationStream(
  product: Product,
  userId?: string
): Promise<AsyncGenerator<Partial<AIRecommendation>>> {
  return getGeminiStream(product, userId);
} 