import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';
import Footer from '../components/Footer';
import { blogPosts } from '../data/blogPosts';
import type { BlogPost } from '../types/blog';

export default function BlogPost() {
  const { slug } = useParams();
  const post = blogPosts.find(post => post.slug === slug) as BlogPost | undefined;

  if (!post) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main className="pt-24 pb-16">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">Post Not Found</h1>
            <p className="mt-4 text-gray-600">The blog post you're looking for doesn't exist.</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": post.title,
    "image": post.featuredImage,
    "datePublished": post.publishDate,
    "dateModified": post.lastModified,
    "author": {
      "@type": "Person",
      "name": post.author
    }
  };

  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Blog', path: '/blog' },
    { label: post.title, path: `/blog/${post.slug}` }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title={`${post.title} - Barcode Bites Blog`}
        description={post.excerpt}
        type="article"
        path={`/blog/${post.slug}`}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
      </Helmet>
      
      <Navbar />
      
      <main className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <Breadcrumbs items={breadcrumbItems} />
          
          <article className="mt-8 bg-white rounded-2xl shadow-sm overflow-hidden">
            <img 
              src={post.featuredImage} 
              alt={post.title}
              className="w-full h-64 object-cover"
            />
            
            <div className="p-8">
              <h1 className="text-3xl font-bold text-gray-900">
                {post.title}
              </h1>
              
              <div className="mt-4 flex items-center text-sm text-gray-500">
                <span>By {post.author}</span>
                <span className="mx-2">•</span>
                <time dateTime={post.publishDate}>
                  {new Date(post.publishDate).toLocaleDateString()}
                </time>
              </div>
              
              <div 
                className="mt-8 prose prose-emerald max-w-none"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
          </article>
        </div>
      </main>
      
      <Footer />
    </div>
  );
} 