import { Link } from 'react-router-dom';
import Logo from './Logo';

export default function Footer() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
          <div className="col-span-2 md:col-span-1">
            <Link to="/" onClick={handleClick}>
              <div className="invert brightness-200">
                <Logo />
              </div>
            </Link>
            <p className="text-gray-400 mt-4 text-sm">
              Making food transparency accessible to everyone.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Product</h3>
            <ul className="space-y-2 text-sm text-gray-400">
              <li><Link to="/child-health-profile" onClick={handleClick} className="hover:text-white">Create a Health Profile</Link></li>
              <li><Link to="/pricing" onClick={handleClick} className="hover:text-white">Education</Link></li>
              <li><Link to="/blog" onClick={handleClick} className="hover:text-white">Blog</Link></li>
              <li><Link to="/contact" onClick={handleClick} className="hover:text-white">Contact Us</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2 text-sm text-gray-400">
              <li><Link to="/privacy" onClick={handleClick} className="hover:text-white">Privacy Policy</Link></li>
              <li><Link to="/scanner" onClick={handleClick} className="hover:text-white">Terms of Service</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-sm text-gray-400">
          <p>© {new Date().getFullYear()} Barcode Bites. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}