import { Check } from 'lucide-react';
import Navbar from '../components/Navbar';
import Testimonials from '../components/Testimonials';
import SEO from '../components/SEO';
import { Helmet } from 'react-helmet-async';

// Comment out the tiers array
/*
const tiers = [
  {
    name: 'Base Plan',
    price: '17',
    priceId: 'price_1QfQqxGUPHP3wnnXznd9jUhz',
    // ... rest of tier definitions
  },
  // ... other tiers
];
*/

// const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export default function PricingPage() {
  // Comment out the state and handlers
  /*
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSubscribe = async (priceId: string) => {
    // ... subscription handling code
  };
  */

  // Keep the schema but modify it to be a free product
  const pricingSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Barcode Bites",
    "description": "Educational tool for food ingredient analysis and nutrition insights",
    "offers": [{
      "@type": "Offer",
      "name": "Educational Access",
      "price": "0",
      "priceCurrency": "USD",
      "description": "Educational tool for families",
      "availability": "https://schema.org/InStock"
    }]
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title="Barcode Bites"
        description="AI-powered food recommendations and ingredient analysis. Free access to all features."
        keywords="food scanner, barcode scanner, children's health app, food safety"
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(pricingSchema)}
        </script>
      </Helmet>
      <Navbar />
      <main className="pt-20">
        <section className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h1 className="text-4xl md:text-5xl text-gray-900 font-serif">
                Join the <span className="text-emerald-600"><strong>largest</strong></span> community of parents <i><strong>learning</strong></i> about the food industry
              </h1>
              <p className="mt-4 text-xl text-gray-600">
                <strong>Educational Tool for Families</strong>
              </p>
            </div>

            {/* Replace pricing tiers with a single free tier */}
            <div className="max-w-lg mx-auto">
              <div className="bg-white border-2 border-emerald-500 shadow-xl rounded-2xl p-8">
                <div className="text-center">
                  <h3 className="text-2xl font-bold text-gray-900">Educational Access</h3>
                  <p className="mt-4 text-sm text-gray-600">Empowering families with nutritional knowledge</p>
                </div>

                <ul className="mt-8 space-y-4">
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-emerald-500 shrink-0" />
                    <span className="ml-3 text-gray-600">
                      <strong>Unlimited barcode scans</strong>
                    </span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-emerald-500 shrink-0" />
                    <span className="ml-3 text-gray-600">
                      <strong>Unlimited personalized child health profiles</strong>
                    </span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-emerald-500 shrink-0" />
                    <span className="ml-3 text-gray-600">Personalized food recommendations</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-emerald-500 shrink-0" />
                    <span className="ml-3 text-gray-600">Harmful ingredient alerts</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-emerald-500 shrink-0" />
                    <span className="ml-3 text-gray-600">Nutritional insights</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <Testimonials />
      </main>
    </div>
  );
}