import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useState, useEffect } from 'react';
import { getChildProfiles } from '../services/userService';
import { supabase } from '../lib/supabase';

interface Stat {
  value: string;
  label: string;
}

const stats: Stat[] = [
  { value: '50K+', label: 'Harmful Ingredients Found' },
  { value: '4M+', label: 'Products in Our Database' },
  { value: '24/7', label: 'Educational Support' }
];

export default function CallToAction() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [hasChildProfile, setHasChildProfile] = useState<boolean | null>(null);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (user?.id) {
        try {
          // Check for child profiles
          const profiles = await getChildProfiles(user.id);
          setHasChildProfile(profiles.length > 0);
          
          // Check subscription status
          const { data: subscription } = await supabase
            .from('subscriptions')
            .select('status')
            .eq('user_id', user.id)
            .single();
          
          setHasSubscription(subscription?.status === 'active');
        } catch (error) {
          console.error('Error checking user status:', error);
          setHasChildProfile(false);
          setHasSubscription(false);
        }
      }
    };

    checkUserStatus();
  }, [user]);

  const handleUserFlow = (initialDestination: string) => {
    // Step 1: Check if user is logged in
    if (!user) {
      navigate('/signup');
      window.scrollTo(0, 0);
      return;
    }

    // Step 2: Check if user has child profile
    if (!hasChildProfile) {
      navigate('/child-health-profile');
      window.scrollTo(0, 0);
      return;
    }

    // Step 3: Check if user has active subscription
    if (!hasSubscription) {
      navigate('/pricing');
      window.scrollTo(0, 0);
      return;
    }

    // If all checks pass, go to intended destination
    navigate(initialDestination);
    window.scrollTo(0, 0);
  };

  return (
    <section className="py-24 bg-gradient-to-b from-emerald-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
            Join Thousands of People Making
            <span className="block text-emerald-600">Safer Food Choices</span>
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
            Start protecting you and your family from harmful ingredients today
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <button
              onClick={() => handleUserFlow('/scan')}
              className="px-8 py-4 bg-emerald-600 text-white rounded-full text-lg font-medium hover:bg-emerald-700 transition-all"
              aria-label="Start scanning products"
            >
              {!user 
                ? "Try Now" 
                : !hasChildProfile 
                  ? "Try Now" 
                  : !hasSubscription 
                    ? "Try Now" 
                    : "Start Scanning"}
            </button>
            <button
              onClick={() => handleUserFlow('/child-health-profile')}
              className="px-8 py-4 bg-white text-emerald-600 rounded-full text-lg font-medium hover:bg-emerald-50 border-2 border-emerald-600 transition-all flex items-center gap-2 group"
              aria-label="Create child health profile"
            >
              Create a Health Profile
              <ArrowRight className="h-5 w-5 transform group-hover:translate-x-1 transition-transform" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-4xl font-bold text-emerald-600 mb-2">{stat.value}</div>
              <div className="text-gray-600">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
