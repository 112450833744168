import { Scan } from 'lucide-react';

export default function Logo() {
  return (
    <div className="flex items-center gap-1.5 group">
      <div className="relative transition-transform duration-300 ease-in-out group-hover:scale-110">
        <Scan className="h-10 w-10 text-emerald-600 transition-colors duration-300 ease-in-out group-hover:text-emerald-500" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-[16px] font-bold text-emerald-600 transition-colors duration-300 ease-in-out group-hover:text-emerald-500">
            BB
          </div>
        </div>
      </div>
      <span className="ml-2 text-2xl font-bold text-gray-900 tracking-tight">
        Barcode<span className="text-emerald-600 transition-colors duration-300 ease-in-out group-hover:text-emerald-500">Bites</span>
      </span>
    </div>
  );
}