import type { BlogPost } from '../types/blog';

export const blogPosts: BlogPost[] = [
  {
    title: "Understanding Food Labels: A Parent's Guide",
    excerpt: "Learn how to decode nutrition labels and make informed decisions about your children's food.",
    slug: "understanding-food-labels",
    publishDate: "2024-03-14",
    lastModified: "2024-03-14",
    readTime: "5 min read",
    featuredImage: "https://images.unsplash.com/photo-1588964895597-cfccd6e2dbf9?auto=format&fit=crop&q=80",
    author: "Nutrition Expert",
    content: `<p>Understanding food labels is crucial for making informed decisions about your family's nutrition...</p>`
  },
  {
    title: "Common Food Additives to Avoid",
    excerpt: "Discover which food additives might be harmful to your children's health and how to avoid them.",
    slug: "food-additives-to-avoid",
    publishDate: "2024-03-10",
    lastModified: "2024-03-10",
    readTime: "4 min read",
    featuredImage: "https://images.unsplash.com/photo-1576021182211-9ea8dced3690?auto=format&fit=crop&q=80",
    author: "Health Researcher",
    content: `<p>When it comes to processed foods, there are several additives that you might want to avoid...</p>`
  }
]; 