import { useState, useEffect } from 'react';
import { Camera, X, ArrowRight, Scan } from 'lucide-react';
import PreviewScanner from './PreviewScanner';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getChildProfiles } from '../services/userService';
import { supabase } from '../lib/supabase';

export default function ScannerPreview() {
  const [showScanner, setShowScanner] = useState(false);
  const [hasChildProfile, setHasChildProfile] = useState<boolean | null>(null);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserStatus = async () => {
      if (user?.id) {
        try {
          // Check for child profiles
          const profiles = await getChildProfiles(user.id);
          setHasChildProfile(profiles.length > 0);
          
          // Check subscription status
          const { data: subscription } = await supabase
            .from('subscriptions')
            .select('status')
            .eq('user_id', user.id)
            .single();
          
          setHasSubscription(subscription?.status === 'active');
        } catch (error) {
          console.error('Error checking user status:', error);
          setHasChildProfile(false);
          setHasSubscription(false);
        }
      }
    };

    checkUserStatus();
  }, [user]);

  const handleScanResult = (result: string) => {
    alert(`Scanned barcode: ${result}\n\nSign up to see detailed ingredient analysis!`);
    setShowScanner(false);
  };

  const handleError = (error: string) => {
    alert(error);
    setShowScanner(false);
  };

  const handleCTAClick = () => {
    // Step 1: Check if user is logged in
    if (!user) {
      navigate('/signup');
      window.scrollTo(0, 0);
      return;
    }

    // Step 2: Check if user has child profile
    if (!hasChildProfile) {
      navigate('/child-health-profile');
      window.scrollTo(0, 0);
      return;
    }

    // Step 3: Check if user has active subscription
    if (!hasSubscription) {
      navigate('/pricing');
      window.scrollTo(0, 0);
      return;
    }

    // Step 4: If all checks pass, open scanner
    setShowScanner(true);
  };

  return (
    <section className="py-12 sm:py-16 bg-gradient-to-b from-white to-emerald-50/30">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          {/* Interactive Prompt */}
          <div className="text-center mb-12">
            <span className="text-gray-900 font-medium">Try Now</span>
            <div className="mt-4"></div>
            <h2 className="mt-2 text-3xl md:text-4xl font-bold text-gray-900">
              <span className="text-emerald-600">Click Below</span> to Start Scanning
            </h2>
          </div>

          {showScanner ? (
            <div className="relative rounded-2xl overflow-hidden shadow-xl">
              <PreviewScanner onResult={handleScanResult} onError={handleError} />
              <button
                onClick={() => setShowScanner(false)}
                className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
              >
                <X className="h-5 w-5 text-gray-600" />
              </button>
            </div>
          ) : (
            <div className="relative">
              {/* Interactive Demo Card */}
              <button
                onClick={() => setShowScanner(true)}
                className="w-full bg-white rounded-2xl shadow-xl overflow-hidden group cursor-pointer relative border-2 border-emerald-100 ring-1 ring-emerald-200/50 shadow-emerald-100/50"
              >
                {/* Container with mobile-only fixed height */}
                <div className="relative w-full h-[375px] sm:h-auto sm:aspect-[16/9]"> {/* Fixed height on mobile, aspect ratio on larger screens */}
                  {/* Phone Frame - Same size as before */}
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[140px] sm:w-[180px] md:w-[220px] h-[280px] sm:h-[360px] md:h-[440px] transform -rotate-12 transition-transform duration-500 group-hover:-rotate-6">
                    {/* Outer Metallic Frame */}
                    <div className="absolute -inset-[3px] bg-gradient-to-br from-gray-400 via-gray-200 to-gray-600 rounded-[2.2rem] sm:rounded-[2.7rem] md:rounded-[3.2rem] shadow-lg">
                      {/* Metallic Shine Effect */}
                      <div className="absolute inset-0 bg-gradient-to-tr from-white/60 via-transparent to-white/20 rounded-[2.2rem] sm:rounded-[2.7rem] md:rounded-[3.2rem]" />
                    </div>
                    
                    {/* Main Phone Body */}
                    <div className="absolute inset-0 bg-[#1A1A1C] rounded-[2rem] sm:rounded-[2.5rem] md:rounded-[3rem] overflow-hidden">
                      {/* Premium Glass Effect */}
                      <div className="absolute inset-0 bg-gradient-to-br from-gray-900/80 to-black/90" />
                      <div className="absolute inset-0 bg-gradient-to-b from-white/10 via-transparent to-white/5" />
                      
                      {/* Camera Island */}
                      <div className="absolute top-2 inset-x-0 flex justify-center">
                        <div className="w-20 sm:w-24 h-4 sm:h-5 bg-black rounded-full flex items-center justify-center shadow-inner">
                          <div className="w-2.5 h-2.5 sm:w-3 sm:h-3 rounded-full bg-black border-2 border-gray-700 mr-2" />
                          <div className="w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full bg-gray-800" />
                          <div className="w-6 sm:w-8 h-1 sm:h-1.5 bg-gray-900 rounded-full mx-1 shadow-inner" />
                        </div>
                      </div>

                      {/* Phone Screen */}
                      <div className="absolute inset-1 bg-emerald-50 rounded-[1.75rem] sm:rounded-[2.25rem] md:rounded-[2.75rem] overflow-hidden shadow-inner">
                        {/* Camera UI */}
                        <div className="absolute inset-0 flex flex-col">
                          {/* Camera Header */}
                          <div className="p-1.5 sm:p-3 text-center bg-emerald-600 text-white">
                            <div className="flex items-center justify-center gap-1.5 sm:gap-2">
                              <Camera className="h-3 sm:h-5 w-3 sm:w-5" />
                              <span className="text-[10px] sm:text-sm font-medium">Click to Scan</span>
                            </div>
                          </div>
                          
                          {/* Scanning Area */}
                          <div className="flex-1 relative bg-black/90">
                            {/* Scanning Frame */}
                            <div className="absolute inset-3 sm:inset-6 border-2 border-emerald-400 rounded-lg">
                              {/* Corner Markers */}
                              <div className="absolute -top-1 -left-1 w-3 sm:w-4 h-3 sm:h-4 border-t-2 border-l-2 border-emerald-400" />
                              <div className="absolute -top-1 -right-1 w-3 sm:w-4 h-3 sm:h-4 border-t-2 border-r-2 border-emerald-400" />
                              <div className="absolute -bottom-1 -left-1 w-3 sm:w-4 h-3 sm:h-4 border-b-2 border-l-2 border-emerald-400" />
                              <div className="absolute -bottom-1 -right-1 w-3 sm:w-4 h-3 sm:h-4 border-b-2 border-r-2 border-emerald-400" />
                              
                              {/* Scanning Animation */}
                              <div className="absolute inset-0 overflow-hidden">
                                <div className="absolute inset-x-0 top-0 h-full animate-scan">
                                  <div className="h-0.5 w-full bg-emerald-400" />
                                </div>
                              </div>

                              {/* Center Text */}
                              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-28 sm:w-36 md:w-44 text-center">
                                <div className="bg-emerald-400/20 backdrop-blur-sm px-2 sm:px-3 py-1.5 sm:py-2 rounded-lg border border-emerald-400/50">
                                  <div className="flex items-center justify-center gap-1 sm:gap-1.5">
                                    <div className="relative">
                                      <Scan className="h-4 sm:h-5 md:h-6 w-4 sm:w-5 md:w-6 text-white transition-colors" />
                                      <div className="absolute inset-0 flex items-center justify-center">
                                        <div className="text-[8px] sm:text-[10px] md:text-xs font-bold text-white">
                                          BB
                                        </div>
                                      </div>
                                    </div>
                                    <span className="text-[8px] sm:text-[10px] md:text-sm font-bold text-white tracking-tight">
                                      Barcode<span className="text-emerald-400">Bites</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Bottom CTA button - Added more spacing */}
      <div className="mt-16 text-center">
        <button
          onClick={handleCTAClick}
          className="inline-flex items-center justify-center px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors shadow-sm hover:shadow-md group gap-2 text-sm md:text-base"
        >
          Start Scanning Now
          <ArrowRight className="h-4 w-4 md:h-5 md:w-5 group-hover:translate-x-1 transition-transform" />
        </button>
      </div>
    </section>
  );
}