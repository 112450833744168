import { supabase } from '../lib/supabase';
import { fetchProduct } from './foodApi';
import type { Database, Product, ProductInsert } from '../types/supabase';

interface OpenFoodFactsProduct {
  status: number;
  product: {
    product_name?: string | null;
    brands?: string | null;
    ingredients_text?: string | null;
    serving_size?: string | null;
    nutriments?: {
      'energy-kcal_serving'?: number | null;
      proteins_serving?: number | null;
      carbohydrates_serving?: number | null;
      fat_serving?: number | null;
    } | null;
    allergens_hierarchy?: string[] | null;
    nutriscore_grade?: string | null;
    image_url?: string | null;
  };
}

export async function getProduct(barcode: string): Promise<Product> {
  try {
    // First, try to get from Supabase
    const { data: existingProduct, error: fetchError } = await supabase
      .from('products')
      .select('*')
      .eq('barcode', barcode)
      .maybeSingle();

    if (fetchError) {
      console.error('Error fetching from Supabase:', fetchError);
      throw new Error('Failed to fetch product data');
    }

    // Check if product exists and is fresh (less than 7 days old)
    if (existingProduct && isProductFresh(existingProduct.last_updated)) {
      return existingProduct;
    }

    // If not found or needs refresh, fetch from Open Food Facts
    const offData = await fetchProduct(barcode);
    
    // Transform and prepare for upsert
    const transformedProduct = transformProductData(offData, barcode);

    // Calculate completeness before upsert
    const completeness = calculateDataCompleteness(transformedProduct);
    transformedProduct.metadata = {
      completeness,
      last_checked: new Date().toISOString()
    };

    // Log the transformed data for debugging
    console.log('Transformed product data:', transformedProduct);

    try {
      // Use upsert with explicit column selection
      const { data: upsertedProduct, error: upsertError } = await supabase
        .from('products')
        .upsert({
          barcode: transformedProduct.barcode,
          name: transformedProduct.name,
          brand: transformedProduct.brand,
          ingredients: transformedProduct.ingredients,
          serving_size: transformedProduct.serving_size,
          calories_per_serving: transformedProduct.calories_per_serving,
          protein_per_serving: transformedProduct.protein_per_serving,
          carbs_per_serving: transformedProduct.carbs_per_serving,
          fat_per_serving: transformedProduct.fat_per_serving,
          allergens: transformedProduct.allergens,
          nutriscore: transformedProduct.nutriscore,
          image_url: transformedProduct.image_url,
          last_updated: transformedProduct.last_updated,
          metadata: transformedProduct.metadata
        }, {
          onConflict: 'barcode'
        })
        .select()
        .single();

      if (upsertError) {
        console.error('Error upserting product:', upsertError);
        throw new Error('Failed to save product data');
      }

      return upsertedProduct;
    } catch (upsertError) {
      console.error('Error during upsert:', upsertError);
      
      // If upsert fails, try to get the existing product one more time
      const { data: fallbackProduct, error: fallbackError } = await supabase
        .from('products')
        .select('*')
        .eq('barcode', barcode)
        .single();

      if (fallbackError) {
        throw new Error('Failed to save or retrieve product data');
      }

      return fallbackProduct;
    }

  } catch (error) {
    console.error('Error in getProduct:', error);
    throw error;
  }
}

// Helper functions
function isProductFresh(lastUpdated: string): boolean {
  const updateDate = new Date(lastUpdated);
  const now = new Date();
  const daysSinceUpdate = (now.getTime() - updateDate.getTime()) / (1000 * 60 * 60 * 24);
  return daysSinceUpdate < 7;
}

function transformProductData(offData: OpenFoodFactsProduct, barcode: string): ProductInsert {
  // Check if offData exists and has a product property
  if (!offData || typeof offData !== 'object') {
    throw new Error('Invalid response from Open Food Facts');
  }

  // If product is missing, create an empty product object
  const product = offData.product || {};

  // Clean and validate numeric values
  const cleanNumber = (value: number | null | undefined): number | null => {
    if (typeof value === 'number' && !isNaN(value)) {
      return value;
    }
    return null;
  };

  // Clean allergens array
  const cleanAllergens = (allergens: string[] | null | undefined): string[] => {
    if (!allergens || !Array.isArray(allergens)) {
      return [];
    }
    return allergens.filter(allergen => allergen && typeof allergen === 'string');
  };

  // Return transformed data with fallbacks for all fields
  return {
    barcode,
    name: product.product_name?.trim() || null,
    brand: product.brands?.trim() || null,
    ingredients: product.ingredients_text?.trim() || null,
    serving_size: product.serving_size?.trim() || null,
    calories_per_serving: cleanNumber(product.nutriments?.['energy-kcal_serving']),
    protein_per_serving: cleanNumber(product.nutriments?.proteins_serving),
    carbs_per_serving: cleanNumber(product.nutriments?.carbohydrates_serving),
    fat_per_serving: cleanNumber(product.nutriments?.fat_serving),
    allergens: cleanAllergens(product.allergens_hierarchy),
    nutriscore: product.nutriscore_grade?.trim() || null,
    image_url: product.image_url?.trim() || null,
    last_updated: new Date().toISOString(),
    metadata: {
      completeness: 0, // Will be calculated before upsert
      last_checked: new Date().toISOString()
    }
  };
}

// Helper function to calculate how complete the product data is
function calculateDataCompleteness(product: ProductInsert): number {
  const fields = [
    'name',
    'brand',
    'ingredients',
    'serving_size',
    'calories_per_serving',
    'protein_per_serving',
    'carbs_per_serving',
    'fat_per_serving',
    'allergens',
    'nutriscore',
    'image_url'
  ];

  const filledFields = fields.filter(field => 
    product[field as keyof ProductInsert] !== null
  ).length;

  return Math.round((filledFields / fields.length) * 100);
}

export async function recordScan(userId: string, barcode: string, notes?: string) {
  try {
    // First, ensure the product exists in the database
    const product = await getProduct(barcode);
    
    if (!product.id) {
      throw new Error('Product ID is missing after creation');
    }
    
    // Get user data from Supabase auth
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;

    // Get user's full name from stripe_customers table
    const { data: customerData, error: customerError } = await supabase
      .from('stripe_customers')
      .select('full_name, email')
      .eq('user_id', userId)
      .single();

    if (customerError && customerError.code !== 'PGRST116') { // PGRST116 is "not found"
      console.error('Error fetching customer data:', customerError);
    }

    // Record the scan with the new fields
    const { data: scanRecord, error } = await supabase
      .from('scan_history')
      .insert({
        user_id: userId,
        product_id: product.id,
        notes,
        created_at: new Date().toISOString(),
        full_name: customerData?.full_name || user?.user_metadata?.full_name || null,
        email: customerData?.email || user?.email || null,
        product_name: product.name,
        product_brand: product.brand
      })
      .select()
      .single();

    if (error) {
      console.error('Error recording scan:', error);
      throw new Error('Failed to record scan');
    }

    return scanRecord;
  } catch (error) {
    console.error('Error in recordScan:', error);
    throw error;
  }
}

export async function getUserScanHistory(userId: string) {
  const { data: history, error } = await supabase
    .from('scan_history')
    .select(`
      *,
      products (*)
    `)
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching scan history:', error);
    throw new Error('Failed to fetch scan history');
  }

  return history;
}

export async function getUserPreferences(userId: string) {
  const { data: preferences, error } = await supabase
    .from('user_preferences')
    .select('*')
    .eq('user_id', userId)
    .single();

  if (error && error.code !== 'PGRST116') { // PGRST116 is "not found"
    console.error('Error fetching user preferences:', error);
    throw new Error('Failed to fetch user preferences');
  }

  return preferences;
}

export async function updateUserPreferences(
  userId: string,
  preferences: Partial<Database['public']['Tables']['user_preferences']['Update']>
) {
  const { error } = await supabase
    .from('user_preferences')
    .upsert({
      user_id: userId,
      ...preferences,
    });

  if (error) {
    console.error('Error updating user preferences:', error);
    throw new Error('Failed to update user preferences');
  }
}

export function preloadCommonProducts() {
  const commonBarcodes = [
    '044000032029', // Lunchables
    '810116121557', // PRIME
    '028400090605', // Veggie Straws
    // Add more common products
  ];
  
  // Fetch in background without blocking
  commonBarcodes.forEach(barcode => {
    getProduct(barcode).catch(() => {
      // Silently fail for preloading
    });
  });
}

// Call this when app starts
preloadCommonProducts();