import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import ScannerPreview from '../components/ScannerPreview';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { Helmet } from 'react-helmet-async';

function Home() {
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Barcode Bites",
    "applicationCategory": "Food & Nutrition",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "Scan food barcodes to get personalized recommendations based on your child's unique health profile.",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1250"
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(homeSchema)}
        </script>
      </Helmet>
      <Navbar />
      <main>
        <Hero />
        <ScannerPreview />
        <HowItWorks />
        <Testimonials />
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
}

export default Home;