import { GoogleGenerativeAI } from "@google/generative-ai";
import type { Product } from '../types/supabase';
import type { AIRecommendation } from '../types/ai';
import type { HealthProfile } from './userService';
import { getChildProfiles } from './userService';

function validateAIResponse(response: any): AIRecommendation {
  if (typeof response !== 'object' || response === null) {
    throw new Error('Invalid AI response format');
  }

  if (typeof response.suitable !== 'boolean' ||
      typeof response.reasoning !== 'string' ||
      !Array.isArray(response.healthyIngredients) ||
      !Array.isArray(response.concerningIngredients)) {
    throw new Error('Invalid AI response structure');
  }

  return {
    suitable: response.suitable,
    reasoning: response.reasoning,
    healthyIngredients: response.healthyIngredients.map(String),
    concerningIngredients: response.concerningIngredients.map(String)
  };
}

export async function analyzeProductForFamily(
  product: Product,
  childProfiles: HealthProfile[] = []
): Promise<AIRecommendation> {
  const genAI = new GoogleGenerativeAI(import.meta.env.VITE_GEMINI_API_KEY);
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });

  const prompt = `You are a caring and knowledgeable nutritionist helping people make informed decisions about food products for themselves and their families. Your goal is to provide clear, science-based advice while being understanding of the challenges in making healthy food choices. ${childProfiles.length > 0 ? 'Please analyze this food product considering the following health profiles:' : 'Please analyze this product\'s nutritional value and safety:'}

${childProfiles.length > 0 ? childProfiles.map(profile => `
${profile.name} (${profile.age} years old):
- Relationship: ${profile.relationship}
- Allergies: ${profile.allergen_alerts.join(', ') || 'None'}
- Dietary Restrictions: ${profile.dietary_restrictions.join(', ') || 'None'}
- Health Concerns: ${profile.health_concerns.join(', ') || 'None'}
- Activity Level: ${profile.activity_level || 'Not specified'}
`).join('\n') : ''}

Product Information:
Name: ${product.name || 'Unknown'}
Brand: ${product.brand || 'Unknown'}
Ingredients: ${product.ingredients || 'Not provided'}
Allergens: ${product.allergens?.join(', ') || 'None listed'}
Nutrition per serving:
- Calories: ${product.calories_per_serving || 'Not provided'}
- Protein: ${product.protein_per_serving !== undefined ? product.protein_per_serving + 'g' : 'Not provided'}
- Carbs: ${product.carbs_per_serving !== undefined ? product.carbs_per_serving + 'g' : 'Not provided'}
- Fat: ${product.fat_per_serving !== undefined ? product.fat_per_serving + 'g' : 'Not provided'}

Communication Guidelines:
1. Use clear, accessible language while maintaining scientific accuracy
2. Explain scientific terms when used
3. Be empathetic and understanding of real-world dietary challenges
4. Provide practical context for your recommendations
5. Please be clear about your recommendation and why you made it

Analysis Instructions:
1. If there are health profiles present, use them to make your recommendation. Reference the specific health needs in your reasoning.
2. Even with missing information, use your expertise to make informed assessments
3. Consider the product name, brand, and available ingredients
4. Clearly explain any assumptions about missing information
5. Be especially careful with allergen-related advice
6. Consider both nutritional value and real-world practicality

${childProfiles.length > 0 
  ? 'Please analyze if this product is suitable for these individuals, considering their specific health needs, nutritional requirements, and lifestyle context.'
  : 'Please provide a balanced analysis of this product\'s nutritional value and health considerations.'}

Return your response in the following JSON format:
{
  "suitable": boolean,
  "reasoning": "Clear explanation that includes scientific insights, practical considerations, and any important assumptions. Balance honesty about nutritional value with understanding of real-world needs.",
  "healthyIngredients": ["List beneficial ingredients/characteristics with clear explanations of their benefits"],
  "concerningIngredients": ["List concerning ingredients/characteristics with balanced, non-alarmist explanations"]
}

Note: If information is very limited, provide your best guidance based on the product category and available details. Stay helpful and practical while being transparent about any uncertainties.`;

  try {
    const result = await model.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    // Extract JSON from the response
    const jsonMatch = text.match(/\{[\s\S]*\}/);
    if (!jsonMatch) {
      throw new Error('Failed to parse AI response');
    }
    
    const parsedResponse = JSON.parse(jsonMatch[0]);
    return validateAIResponse(parsedResponse);
  } catch (error) {
    console.error('Error calling Gemini:', error);
    throw new Error('Failed to analyze product');
  }
}

export async function* getAIRecommendationStream(
  product: Product,
  userId?: string
): AsyncGenerator<Partial<AIRecommendation>> {
  const genAI = new GoogleGenerativeAI(import.meta.env.VITE_GEMINI_API_KEY);
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });

  // Get child profiles if userId is provided
  let childProfiles: HealthProfile[] = [];
  if (userId) {
    try {
      childProfiles = await getChildProfiles(userId);
    } catch (error) {
      console.warn('Error fetching child profiles:', error);
    }
  }

  // Use the same prompt construction as before
  const streamingPrompt = `You are a caring and knowledgeable nutritionist helping people make informed decisions about food products for themselves and their families. Your goal is to provide clear, science-based advice while being understanding of the challenges in making healthy food choices. ${childProfiles.length > 0 ? 'Please analyze this food product considering the following health profiles:' : 'Please analyze this product\'s nutritional value and safety:'}

${childProfiles.length > 0 ? childProfiles.map(profile => `
${profile.name} (${profile.age} years old):
- Relationship: ${profile.relationship}
- Allergies: ${profile.allergen_alerts.join(', ') || 'None'}
- Dietary Restrictions: ${profile.dietary_restrictions.join(', ') || 'None'}
- Health Concerns: ${profile.health_concerns.join(', ') || 'None'}
- Activity Level: ${profile.activity_level || 'Not specified'}
`).join('\n') : ''}

Product Information:
Name: ${product.name || 'Unknown'}
Brand: ${product.brand || 'Unknown'}
Ingredients: ${product.ingredients || 'Not provided'}
Allergens: ${product.allergens?.join(', ') || 'None listed'}
Nutrition per serving:
- Calories: ${product.calories_per_serving || 'Not provided'}
- Protein: ${product.protein_per_serving !== undefined ? product.protein_per_serving + 'g' : 'Not provided'}
- Carbs: ${product.carbs_per_serving !== undefined ? product.carbs_per_serving + 'g' : 'Not provided'}
- Fat: ${product.fat_per_serving !== undefined ? product.fat_per_serving + 'g' : 'Not provided'}

Guidelines:
1. Use accessible language while maintaining accuracy
2. Make clear, confident statements about the product's value
3. Highlight key points using **bold text**
4. Start with your analysis immediately, then structure it as JSON afterward
5. When information is limited, make informed assessments based on product type

${childProfiles.length > 0 
  ? 'Analyze if this product is suitable for these individuals, considering their health needs and nutritional requirements.'
  : 'Provide a clear analysis of this product\'s nutritional value.'}

Return your response in the following JSON format:
{
  "suitable": boolean,
  "reasoning": "Concise explanation with **key points in bold**. Be direct about whether you recommend this product and why.",
  "healthyIngredients": ["List beneficial ingredients with clear, bold benefits: '**Whole grains** - provides sustained energy'"],
  "concerningIngredients": ["List concerning ingredients with clear, bold warnings: '**Added sugars** - may affect blood sugar levels'"]
}`;

  try {
    // First yield an empty response to show loading state
    yield { reasoning: "Thinking..." };

    const result = await model.generateContentStream([streamingPrompt]);
    let accumulatedText = '';
    
    for await (const chunk of result.stream) {
      accumulatedText += chunk.text();
    }

    // Once we have the complete response, parse it and yield
    const jsonMatch = accumulatedText.match(/\{[\s\S]*\}/);
    if (jsonMatch) {
      try {
        const parsedJson = JSON.parse(jsonMatch[0]);
        yield parsedJson;
      } catch (error) {
        console.error('Error parsing JSON:', error);
        throw new Error('Failed to parse AI response');
      }
    } else {
      throw new Error('Failed to get valid AI response');
    }
  } catch (error) {
    console.error('Error streaming from Gemini:', error);
    throw new Error('Failed to analyze product');
  }
} 