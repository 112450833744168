import { useState, useEffect } from 'react';
import { Baby, Apple, Brain, User, Target } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useAuth } from '../hooks/useAuth';
import { createChildProfile, updateChildProfile, getChildProfiles } from '../services/userService';
import ChildProfileSummary from '../components/ChildProfileSummary';
import SEO from '../components/SEO';

interface ChildInfo {
  relationship: string;
  name: string;
  age: string;
  activityLevel: string;
  dietaryRestrictions: string[];
  healthConcerns: string[];
  fitnessGoals: string[];
  otherDietaryRestriction: string;
  otherHealthConcern: string;
  otherFitnessGoal: string;
}

const relationshipOptions = [
  { id: 'self', label: 'Myself' },
  { id: 'child', label: 'My Child' },
  { id: 'parent', label: 'My Parent' },
  { id: 'grandparent', label: 'My Grandparent' }
];

const activityLevels = [
  { id: 'low', label: 'Low Activity', description: 'Mostly sedentary, occasional light activity' },
  { id: 'moderate', label: 'Moderate Activity', description: 'Regular exercise and daily activities' },
  { id: 'high', label: 'High Activity', description: 'Very active, regular sports or intense exercise' }
];

const dietaryOptions = [
  { id: 'none', label: 'None' },
  { id: 'vegetarian', label: 'Vegetarian' },
  { id: 'vegan', label: 'Vegan' },
  { id: 'gluten-free', label: 'Gluten-Free' },
  { id: 'dairy-free', label: 'Dairy-Free' },
  { id: 'kosher', label: 'Kosher' },
  { id: 'halal', label: 'Halal' },
  { id: 'other', label: 'Other' }
];

const fitnessGoalOptions = [
  { id: 'weight-loss', label: 'Weight Loss' },
  { id: 'muscle-gain', label: 'Muscle Gain' },
  { id: 'endurance', label: 'Improve Endurance' },
  { id: 'strength', label: 'Build Strength' }
];

const healthConcernOptions = [
  { id: 'none', label: 'None' },
  { id: 'diabetes', label: 'Diabetes' },
  { id: 'heart', label: 'Heart Health' },
  { id: 'obesity', label: 'Manage Weight' }
];

export default function HealthProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const [childInfo, setChildInfo] = useState<ChildInfo>({
    relationship: '',
    name: '',
    age: '',
    activityLevel: '',
    dietaryRestrictions: [],
    healthConcerns: [],
    fitnessGoals: [],
    otherDietaryRestriction: '',
    otherHealthConcern: '',
    otherFitnessGoal: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(!!id); // Loading state for fetching existing profile

  // Fetch existing profile data if editing
  useEffect(() => {
    async function fetchProfile() {
      if (!id || !user?.id) return;

      try {
        const profiles = await getChildProfiles(user.id);
        const profile = profiles.find(p => p.id === id);
        
        if (!profile) {
          setError('Profile not found');
          return;
        }

        // Transform the database data to match our form structure
        setChildInfo({
          relationship: profile.relationship,
          name: profile.name,
          age: profile.age.toString(),
          activityLevel: profile.activity_level || '',
          dietaryRestrictions: profile.dietary_restrictions.filter(r => {
            // Check if it's one of our standard options
            return dietaryOptions.some(opt => opt.id === r) || r === 'none';
          }),
          healthConcerns: profile.health_concerns.filter(c => {
            return healthConcernOptions.some(opt => opt.id === c) || c === 'none';
          }),
          fitnessGoals: profile.fitness_goals.filter(g => {
            return fitnessGoalOptions.some(opt => opt.id === g) || g === 'none';
          }),
          otherDietaryRestriction: profile.dietary_restrictions.find(r => 
            !dietaryOptions.some(opt => opt.id === r) && r !== 'none'
          ) || '',
          otherHealthConcern: profile.health_concerns.find(c => 
            !healthConcernOptions.some(opt => opt.id === c) && c !== 'none'
          ) || '',
          otherFitnessGoal: profile.fitness_goals.find(g => 
            !fitnessGoalOptions.some(opt => opt.id === g) && g !== 'none'
          ) || ''
        });
      } catch (err) {
        setError('Failed to load profile');
        console.error('Error loading profile:', err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProfile();
  }, [id, user?.id]);

  const handleNext = async () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
      // Scroll to top after step change
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    // Submit form
    if (!user?.id) {
      setError('You must be logged in to create a child profile');
      return;
    }

    if (!childInfo.relationship || !childInfo.name || !childInfo.age || !childInfo.activityLevel) {
      setError('Please fill out all required fields');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const dietaryRestrictions = childInfo.dietaryRestrictions
        .filter(r => r !== 'other')
        .filter(r => r !== 'none');
      if (childInfo.otherDietaryRestriction) {
        dietaryRestrictions.push(childInfo.otherDietaryRestriction);
      }

      const healthConcerns = childInfo.healthConcerns
        .filter(c => c !== 'other')
        .filter(c => c !== 'none');
      if (childInfo.otherHealthConcern) {
        healthConcerns.push(childInfo.otherHealthConcern);
      }

      const fitnessGoals = childInfo.fitnessGoals
        .filter(g => g !== 'other')
        .filter(g => g !== 'none');
      if (childInfo.otherFitnessGoal) {
        fitnessGoals.push(childInfo.otherFitnessGoal);
      }

      const profileData = {
        relationship: childInfo.relationship,
        name: childInfo.name,
        age: parseInt(childInfo.age),
        activity_level: childInfo.activityLevel as 'low' | 'moderate' | 'high',
        dietary_restrictions: dietaryRestrictions,
        health_concerns: healthConcerns,
        fitness_goals: fitnessGoals,
        allergen_alerts: [] 
      };

      if (id) {
        // Update existing profile
        await updateChildProfile(id, profileData);
        navigate('/settings');
      } else {
        // Create new profile
        await createChildProfile(user.id, profileData);
        setIsProfileComplete(true);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save child profile');
      console.error('Error saving child profile:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      // Scroll to top after step change
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const updateChildInfo = (field: keyof ChildInfo, value: string | string[]) => {
    setChildInfo(prev => ({ ...prev, [field]: value }));
  };

  const handleOptionToggle = (field: 'dietaryRestrictions' | 'healthConcerns' | 'fitnessGoals', optionId: string) => {
    setChildInfo(prev => {
      if (optionId === 'none') {
        return {
          ...prev,
          [field]: ['none']
        };
      }
      
      const currentValues = prev[field].filter(item => item !== 'none');
      const newValues = currentValues.includes(optionId)
        ? currentValues.filter(item => item !== optionId)
        : [...currentValues, optionId];
      
      return {
        ...prev,
        [field]: newValues
      };
    });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main className="pt-24 pb-16">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <div className="flex justify-center items-center h-64">
                <div className="text-center">
                  <div className="animate-spin inline-block w-8 h-8 border-4 border-emerald-500 border-t-transparent rounded-full mb-4"></div>
                  <p className="text-gray-600">Loading profile...</p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main className="pt-24 pb-16">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <div className="text-center text-red-600 py-8">
                {error}
                <button
                  onClick={() => navigate('/settings')}
                  className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                >
                  Back to Profiles
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title={id ? "Edit Health Profile - Barcode Bites" : "Create Health Profile - Barcode Bites"}
        description="Set up a personalized health profile for tailored food recommendations and health tracking."
        keywords="health profile, food allergies, dietary restrictions, nutrition tracking, fitness goals"
      />
      <Navbar />
      
      <main className="pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-2xl shadow-sm p-8">
            {isProfileComplete ? (
              <ChildProfileSummary childInfo={childInfo} />
            ) : (
              <>
                {/* Header */}
                <div className="text-center mb-8">
                  <h1 className="text-3xl font-bold text-gray-900">
                    Health Profile
                  </h1>
                  <p className="mt-2 text-gray-600">
                    Help us understand your health needs for better food recommendations
                  </p>
                </div>

                {/* Progress Steps */}
                <div className="mb-8">
                  <div className="flex justify-between items-center">
                    {[1, 2, 3, 4, 5].map((step) => (
                      <div key={step} className="flex items-center">
                        <div className={`
                          w-8 h-8 rounded-full flex items-center justify-center
                          ${currentStep >= step ? 'bg-emerald-600 text-white' : 'bg-gray-200 text-gray-600'}
                        `}>
                          {step}
                        </div>
                        {step < 6 && (
                          <div className={`
                            w-full h-1 mx-2
                            ${currentStep > step ? 'bg-emerald-600' : 'bg-gray-200'}
                          `} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Step Content */}
                <div className="mb-8">
                  {currentStep === 1 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Baby className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Who is this profile for?</h2>
                          <p className="text-gray-600">Select your relationship to this person</p>
                        </div>
                      </div>

                      <div className="space-y-4">
                        {relationshipOptions.map((option) => (
                          <div
                            key={option.id}
                            className={`
                              p-4 border rounded-xl cursor-pointer transition-all
                              ${childInfo.relationship === option.id
                                ? 'border-emerald-500 bg-emerald-50'
                                : 'border-gray-200 hover:border-emerald-200'
                              }
                            `}
                            onClick={() => updateChildInfo('relationship', option.id)}
                          >
                            <div className="flex items-center gap-3">
                              <div className={`
                                w-5 h-5 rounded-full border-2 flex items-center justify-center
                                ${childInfo.relationship === option.id
                                  ? 'border-emerald-500'
                                  : 'border-gray-300'
                                }
                              `}>
                                {childInfo.relationship === option.id && (
                                  <div className="w-3 h-3 rounded-full bg-emerald-500" />
                                )}
                              </div>
                              <div>
                                <h3 className="font-medium text-gray-900">{option.label}</h3>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {currentStep === 2 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <User className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Basic Information</h2>
                          <p className="text-gray-600">Tell us about the person</p>
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Name
                        </label>
                        <input
                          type="text"
                          value={childInfo.name}
                          onChange={(e) => updateChildInfo('name', e.target.value)}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                          placeholder="Enter name"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Age
                        </label>
                        <input
                          type="number"
                          value={childInfo.age}
                          onChange={(e) => updateChildInfo('age', e.target.value)}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                          placeholder="Enter age"
                          min="1"
                          max="120"
                        />
                      </div>

                      <div className="space-y-4">
                        <label className="block text-sm font-medium text-gray-700">
                          Activity Level
                        </label>
                        {activityLevels.map((level) => (
                          <div
                            key={level.id}
                            className={`
                              p-4 border rounded-xl cursor-pointer transition-all
                              ${childInfo.activityLevel === level.id
                                ? 'border-emerald-500 bg-emerald-50'
                                : 'border-gray-200 hover:border-emerald-200'
                              }
                            `}
                            onClick={() => updateChildInfo('activityLevel', level.id)}
                          >
                            <div className="flex items-center gap-3">
                              <div className={`
                                w-5 h-5 rounded-full border-2 flex items-center justify-center
                                ${childInfo.activityLevel === level.id
                                  ? 'border-emerald-500'
                                  : 'border-gray-300'
                                }
                              `}>
                                {childInfo.activityLevel === level.id && (
                                  <div className="w-3 h-3 rounded-full bg-emerald-500" />
                                )}
                              </div>
                              <div>
                                <h3 className="font-medium text-gray-900">{level.label}</h3>
                                <p className="text-sm text-gray-600">{level.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {currentStep === 3 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Apple className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Dietary Restrictions</h2>
                          <p className="text-gray-600">Select any dietary restrictions that apply</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 xs:grid-cols-2 gap-4">
                        {dietaryOptions.map((option) => (
                          <div key={option.id} className="flex flex-col">
                            <button
                              type="button"
                              onClick={() => handleOptionToggle('dietaryRestrictions', option.id)}
                              className={`
                                p-4 border rounded-xl text-left transition-all w-full h-full min-h-[4rem]
                                ${childInfo.dietaryRestrictions.includes(option.id)
                                  ? 'border-emerald-500 bg-emerald-50'
                                  : 'border-gray-200 hover:border-emerald-200'
                                }
                              `}
                            >
                              <div className="flex items-center gap-3">
                                <div className={`
                                  w-5 h-5 rounded flex-shrink-0 flex items-center justify-center border-2
                                  ${childInfo.dietaryRestrictions.includes(option.id)
                                    ? 'border-emerald-500 bg-emerald-500'
                                    : 'border-gray-300'
                                  }
                                `}>
                                  {childInfo.dietaryRestrictions.includes(option.id) && (
                                    <svg className="w-3 h-3 text-white" viewBox="0 0 24 24">
                                      <path
                                        fill="currentColor"
                                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="font-medium text-gray-900">{option.label}</span>
                              </div>
                            </button>
                            
                            {option.id === 'other' && childInfo.dietaryRestrictions.includes('other') && (
                              <div className="mt-2">
                                <input
                                  type="text"
                                  value={childInfo.otherDietaryRestriction}
                                  onChange={(e) => setChildInfo(prev => ({
                                    ...prev,
                                    otherDietaryRestriction: e.target.value
                                  }))}
                                  placeholder="Please specify your dietary restriction"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-emerald-500 focus:border-emerald-500"
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {currentStep === 4 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Target className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Fitness Goals</h2>
                          <p className="text-gray-600">Select any fitness goals you want to achieve</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mb-6">
                        {fitnessGoalOptions.map((option) => (
                          <div key={option.id} className="flex flex-col">
                            <button
                              type="button"
                              onClick={() => handleOptionToggle('fitnessGoals', option.id)}
                              className={`
                                p-4 border rounded-xl text-left transition-all w-full h-full min-h-[4rem]
                                ${childInfo.fitnessGoals.includes(option.id)
                                  ? 'border-emerald-500 bg-emerald-50'
                                  : 'border-gray-200 hover:border-emerald-200'
                                }
                              `}
                            >
                              <div className="flex items-center gap-3">
                                <div className={`
                                  w-5 h-5 rounded flex-shrink-0 flex items-center justify-center border-2
                                  ${childInfo.fitnessGoals.includes(option.id)
                                    ? 'border-emerald-500 bg-emerald-500'
                                    : 'border-gray-300'
                                  }
                                `}>
                                  {childInfo.fitnessGoals.includes(option.id) && (
                                    <svg className="w-3 h-3 text-white" viewBox="0 0 24 24">
                                      <path
                                        fill="currentColor"
                                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="font-medium text-gray-900">{option.label}</span>
                              </div>
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className="mt-8">
                        <div className="mb-4">
                          <h3 className="text-lg font-medium text-gray-900">Tell Us About Your Personal Goals</h3>
                        </div>
                        <textarea
                          value={childInfo.otherFitnessGoal}
                          onChange={(e) => setChildInfo(prev => ({
                            ...prev,
                            otherFitnessGoal: e.target.value
                          }))}
                          placeholder="Examples:&#13;&#10;• I want to run my first 5K race in 3 months&#13;&#10;• I want to improve my flexibility and touch my toes&#13;&#10;• I want to be able to do 10 push-ups in a row"
                          className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-emerald-500 focus:border-transparent min-h-[160px] text-base"
                        />
                      </div>
                    </div>
                  )}

                  {currentStep === 5 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Brain className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Health Considerations</h2>
                          <p className="text-gray-600">Select any health conditions we should consider</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mb-6">
                        {healthConcernOptions.map((option) => (
                          <div key={option.id} className="flex flex-col">
                            <button
                              type="button"
                              onClick={() => handleOptionToggle('healthConcerns', option.id)}
                              className={`
                                p-4 border rounded-xl text-left transition-all w-full h-full min-h-[4rem]
                                ${childInfo.healthConcerns.includes(option.id)
                                  ? 'border-emerald-500 bg-emerald-50'
                                  : 'border-gray-200 hover:border-emerald-200'
                                }
                              `}
                            >
                              <div className="flex items-center gap-3">
                                <div className={`
                                  w-5 h-5 rounded flex-shrink-0 flex items-center justify-center border-2
                                  ${childInfo.healthConcerns.includes(option.id)
                                    ? 'border-emerald-500 bg-emerald-500'
                                    : 'border-gray-300'
                                  }
                                `}>
                                  {childInfo.healthConcerns.includes(option.id) && (
                                    <svg className="w-3 h-3 text-white" viewBox="0 0 24 24">
                                      <path
                                        fill="currentColor"
                                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <span className="font-medium text-gray-900">{option.label}</span>
                              </div>
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className="mt-8">
                        <div className="mb-4">
                          <h3 className="text-lg font-medium text-gray-900">Tell Us About Your Health</h3>
                        </div>
                        <textarea
                          value={childInfo.otherHealthConcern}
                          onChange={(e) => setChildInfo(prev => ({
                            ...prev,
                            otherHealthConcern: e.target.value
                          }))}
                          placeholder="Examples:&#13;&#10;• I get headaches when I eat certain foods&#13;&#10;• My joints are stiff in the morning&#13;&#10;• I need more energy throughout the day"
                          className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-emerald-500 focus:border-transparent min-h-[160px] text-base"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Navigation Buttons */}
                <div className="flex justify-between">
                  <button
                    onClick={handleBack}
                    className={`
                      px-6 py-2 rounded-lg border border-gray-300 text-gray-700
                      hover:bg-gray-50 transition-colors
                      ${currentStep === 1 ? 'invisible' : ''}
                    `}
                    disabled={isSubmitting}
                  >
                    Back
                  </button>
                  <button
                    onClick={handleNext}
                    disabled={isSubmitting}
                    className="px-6 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting 
                      ? 'Saving...' 
                      : currentStep === 5
                        ? 'Complete Profile' 
                        : 'Next'
                    }
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </main>

      {/* Add error message above navigation buttons */}
      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-md">
          {error}
        </div>
      )}
    </div>
  );
}