import { useState } from 'react';
import { CreditCard, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';

export default function AdvancedSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePortalSession = async () => {
    try {
      setIsLoading(true);
      
      // Get current session and log details
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        console.error('Session error:', sessionError);
        navigate('/login');
        return;
      }
      
      if (!session?.access_token) {
        console.error('No valid session found');
        navigate('/login');
        return;
      }

      // Log token details
      console.log('Token length:', session.access_token.length);
      console.log('Token starts with:', session.access_token.substring(0, 20) + '...');

      // Make the request
      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-portal-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
          // Remove the apikey header as it's not needed for auth
        },
        body: JSON.stringify({
          returnUrl: `${window.location.origin}/settings`
        })
      });

      // Log the full response headers
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Portal session error:', {
          status: response.status,
          text: errorText,
          headers: Object.fromEntries(response.headers.entries())
        });
        
        if (response.status === 401) {
          console.log('Attempting session refresh...');
          const { data: { session: newSession }, error: refreshError } = 
            await supabase.auth.refreshSession();
          
          if (refreshError || !newSession) {
            console.error('Session refresh failed:', refreshError);
            // Try signing out and back in
            await supabase.auth.signOut();
            navigate('/login');
            return;
          }

          console.log('New token length:', newSession.access_token.length);
          console.log('New token starts with:', newSession.access_token.substring(0, 20) + '...');

          // Retry with new token
          const retryResponse = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-portal-session`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newSession.access_token}`
            },
            body: JSON.stringify({
              returnUrl: `${window.location.origin}/settings`
            })
          });

          if (!retryResponse.ok) {
            const retryErrorText = await retryResponse.text();
            console.error('Retry failed:', {
              status: retryResponse.status,
              text: retryErrorText,
              headers: Object.fromEntries(retryResponse.headers.entries())
            });
            throw new Error(`Failed to create portal session after refresh: ${retryErrorText}`);
          }

          const { url } = await retryResponse.json();
          window.location.href = url;
          return;
        }
        
        throw new Error(`Failed to create portal session: ${errorText}`);
      }

      const { url } = await response.json();
      if (!url) {
        throw new Error('No portal URL received from server');
      }

      window.location.href = url;
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to access billing portal. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-6">Advanced Settings</h2>
      
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-900">Billing & Subscription</h3>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800">
            Base Plan
          </span>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-4">
            <div className="flex-shrink-0">
              <CreditCard className="h-5 w-5 text-gray-400" />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900">Payment Method</p>
              <p className="text-sm text-gray-500">Visa ending in 4242</p>
            </div>
            <button 
              onClick={handlePortalSession}
              className="text-xs text-emerald-600 hover:text-emerald-700 font-medium"
            >
              Update
            </button>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex-shrink-0">
              <Shield className="h-5 w-5 text-gray-400" />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900">Billing History</p>
              <p className="text-sm text-gray-500">Next billing date: April 1, 2024</p>
            </div>
            <button 
              onClick={handlePortalSession}
              className="text-xs text-emerald-600 hover:text-emerald-700 font-medium"
            >
              View
            </button>
          </div>
        </div>

        <div className="pt-4">
          <button
            onClick={handlePortalSession}
            disabled={isLoading}
            className="text-xs text-gray-600 hover:text-gray-900 disabled:opacity-50"
          >
            {isLoading ? 'Loading...' : 'View plan details'}
          </button>
        </div>
      </div>
    </div>
  );
} 