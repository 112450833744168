import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles } from 'lucide-react';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { useSubscription } from '../hooks/useSubscription';

interface HealthInfo {
  relationship: string;
  name: string;
  age: string;
  activityLevel: string;
  dietaryRestrictions: string[];
  healthConcerns: string[];
  fitnessGoals: string[];
  otherDietaryRestriction?: string;
  otherHealthConcern?: string;
  otherFitnessGoal?: string;
}

interface Props {
  childInfo: HealthInfo;
}

export default function HealthProfileSummary({ childInfo }: Props) {
  const navigate = useNavigate();
  const { isSubscribed, isLoading: isCheckingSubscription } = useSubscription();
  const [summary, setSummary] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    generateSummary();
  }, []);

  const generateSummary = async () => {
    setIsLoading(true);
    try {
      const processedDietaryRestrictions = childInfo.dietaryRestrictions.map(restriction => 
        restriction === 'other' && childInfo.otherDietaryRestriction
          ? `other: ${childInfo.otherDietaryRestriction}`
          : restriction
      );

      const processedHealthConcerns = childInfo.healthConcerns.map(concern =>
        concern === 'other' && childInfo.otherHealthConcern
          ? `other: ${childInfo.otherHealthConcern}`
          : concern
      );

      const processedFitnessGoals = childInfo.fitnessGoals.map(goal =>
        goal === 'other' && childInfo.otherFitnessGoal
          ? `other: ${childInfo.otherFitnessGoal}`
          : goal
      );

      const relationshipText = childInfo.relationship === 'self' ? 'your' : `${childInfo.name}'s`;

      const prompt = `You are BarcodeBites, a confident and knowledgeable AI food scanning assistant. Create a brief, high-impact welcome message for ${childInfo.name} (${childInfo.age} years old).

Key Profile:
${childInfo.activityLevel ? `- Activity: ${childInfo.activityLevel}` : ''}
${processedDietaryRestrictions.length > 0 ? `- Diet: ${processedDietaryRestrictions.join(', ')}` : ''}
${processedHealthConcerns.length > 0 ? `- Health: ${processedHealthConcerns.join(', ')}` : ''}
${processedFitnessGoals.length > 0 ? `- Goals: ${processedFitnessGoals.join(', ')}` : ''}

Create a personalized response following this EXACT format:

👋 Welcome ${childInfo.name}! We're excited to help you make informed food choices that perfectly match ${relationshipText} needs.

BarcodeBites will:
🔍 • **Discover fun, healthy foods** that match ${relationshipText} exact needs
⚡️ • **Alert you instantly** about ingredients to avoid
✨ • **Suggest perfect alternatives** based on your preferences

🚀 **Let's make ${relationshipText} [insert specific goal] a reality! Ready to scan?**

Keep it energetic and focused on their specific situation. Make every word count.`;

      const genAI = new GoogleGenerativeAI(import.meta.env.VITE_GEMINI_API_KEY);
      const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });

      const result = await model.generateContent(prompt);
      const response = result.response;
      const text = response.text();
      
      setSummary(text);
    } catch (error) {
      console.error('Error generating summary:', error);
      const relationshipText = childInfo.relationship === 'self' ? 'your' : `${childInfo.name}'s`;
      setSummary(`
👋 Welcome ${childInfo.name}! We're excited to help you make informed food choices that perfectly match ${relationshipText} needs.

BarcodeBites will:
🔍 • **Discover safe and delicious foods** for ${relationshipText} needs
⚡️ • **Instantly flag ingredients** that don't match your preferences
✨ • **Find perfect alternatives** that you'll love

🚀 **Let's make ${relationshipText} healthy food journey a reality! Ready to scan?**
      `);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetStarted = () => {
    if (isSubscribed) {
      navigate('/');
    } else {
      navigate('/pricing');
    }
    window.scrollTo(0, 0);
  };

  // Add this function to format markdown bold text
  const formatMarkdownBold = (text: string) => {
    // First handle bold text
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Then handle bullet points by splitting into lines
    formattedText = formattedText.split('\n').map(line => {
      // If line starts with emoji + bullet point, replace with number and format
      if (line.trim().match(/[🔍⚡️✨] •/)) {
        const content = line.trim().substring(3).trim(); // Skip emoji and bullet
        return `<div class="flex gap-2 mt-4 mb-4">
          <span class="flex-shrink-0 font-medium text-xl" style="width: 32px">${line.trim().slice(0, 2)}</span>
          <span class="text-gray-700">${content}</span>
        </div>`;
      }
      // Style the closing line differently if it contains "Let's"
      if (line.includes("Let's")) {
        return `<p class="mt-6 mb-4 text-lg font-medium text-emerald-700 text-center">${line.trim()}</p>`;
      }
      // Add paragraph tags to non-bullet lines that aren't empty
      return line.trim() ? `<p class="mb-4">${line.trim()}</p>` : '';
    }).join('');

    return formattedText;
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4 mb-6">
        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
          <Sparkles className="w-6 h-6 text-emerald-600" />
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Creating {childInfo.name}'s Personalized Food Plan</h2>
        </div>
      </div>

      {isLoading ? (
        <div className="bg-emerald-50 rounded-xl p-6 space-y-4">
          <div className="flex items-center gap-3 text-emerald-600">
            <div className="animate-spin">
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <circle 
                  className="opacity-25" 
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  strokeWidth="4"
                  fill="none"
                />
                <path 
                  className="opacity-75" 
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
            <span className="font-medium">Crafting your personalized food plan...</span>
          </div>
          <div className="animate-pulse space-y-3">
            <div className="h-4 bg-emerald-100/50 rounded w-3/4"></div>
            <div className="h-4 bg-emerald-100/50 rounded w-5/6"></div>
            <div className="h-4 bg-emerald-100/50 rounded w-2/3"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="prose prose-emerald max-w-none">
            <div className="bg-emerald-50 rounded-xl p-6"
              dangerouslySetInnerHTML={{ __html: formatMarkdownBold(summary) }}
            />
          </div>

          <div className="mt-8 space-y-4">
            <button
              onClick={handleGetStarted}
              disabled={isCheckingSubscription}
              className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50"
            >
              {isCheckingSubscription ? 'Loading...' : "Let's get started!"}
            </button>
          </div>
        </>
      )}
    </div>
  );
} 