import { useEffect, useRef, useState, useCallback } from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser';
import { BarcodeFormat, DecodeHintType } from '@zxing/library';
import { XCircle } from 'lucide-react';
import ProductPopup from './ProductPopup';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { getChildProfiles } from '../services/userService';
import { supabase } from '../lib/supabase';

interface PreviewScannerProps {
  onResult: (result: string) => void;
  onError: (error: string) => void;
}

export default function PreviewScanner({ onResult, onError }: PreviewScannerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const readerRef = useRef<BrowserMultiFormatReader | null>(null);
  const [isScanning, setIsScanning] = useState(false);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState<string>('');
  const { user } = useAuth();
  const navigate = useNavigate();

  const checkUserStatus = useCallback(async () => {
    if (!user) {
      navigate('/signup');
      window.scrollTo(0, 0);
      return false;
    }

    try {
      // Check for child profiles
      const profiles = await getChildProfiles(user.id);
      if (!profiles.length) {
        navigate('/child-health-profile');
        window.scrollTo(0, 0);
        return false;
      }

      // Check subscription status
      const { data: subscription } = await supabase
        .from('subscriptions')
        .select('status')
        .eq('user_id', user.id)
        .single();

      if (subscription?.status !== 'active') {
        navigate('/pricing');
        window.scrollTo(0, 0);
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error checking user status:', error);
      return false;
    }
  }, [user, navigate]);

  const handleScanResult = useCallback(async (barcode: string) => {
    const canProceed = await checkUserStatus();
    if (!canProceed) return;

    setScannedBarcode(barcode);
    setShowPopup(true);
    stopScanning();
  }, [checkUserStatus]);

  const handlePopupClose = () => {
    setShowPopup(false);
    setScannedBarcode('');
    onResult(scannedBarcode);
  };

  const stopScanning = () => {
    setIsScanning(false);

    if (videoRef.current?.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      stream.getTracks().forEach(track => {
        try {
          track.stop();
        } catch {
          // Ignore errors when stopping tracks
        }
      });
      videoRef.current.srcObject = null;
    }

    readerRef.current = null;
  };

  const startScanning = useCallback(async () => {
    const canProceed = await checkUserStatus();
    if (!canProceed) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { 
          facingMode: 'environment',
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
          aspectRatio: { ideal: 1.7777777778 },
          frameRate: { min: 15, ideal: 30 }
        } 
      });
      
      setHasPermission(true);
      
      if (videoRef.current && readerRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
        setIsScanning(true);

        const scanLoop = async () => {
          if (!isScanning || !readerRef.current || !videoRef.current) return;

          try {
            const result = await readerRef.current.decodeOnceFromVideoElement(videoRef.current);
            if (result?.getText()) {
              handleScanResult(result.getText());
            } else {
              requestAnimationFrame(scanLoop);
            }
          } catch {
            if (isScanning) {
              requestAnimationFrame(scanLoop);
            }
          }
        };

        setTimeout(() => {
          void scanLoop();
        }, 1000);
      }
    } catch (error) {
      console.error('Camera error:', error);
      setHasPermission(false);
      onError('Failed to access camera. Please ensure camera permissions are granted.');
    }
  }, [handleScanResult, isScanning, onError, checkUserStatus]);

  useEffect(() => {
    const hints = new Map<DecodeHintType, BarcodeFormat[] | boolean | string>();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [
      BarcodeFormat.EAN_13,
      BarcodeFormat.EAN_8,
      BarcodeFormat.UPC_A,
      BarcodeFormat.UPC_E,
    ]);
    hints.set(DecodeHintType.TRY_HARDER, true);
    hints.set(DecodeHintType.CHARACTER_SET, 'UTF-8');
    hints.set(DecodeHintType.ASSUME_GS1, true);
    hints.set(DecodeHintType.PURE_BARCODE, false);
    
    readerRef.current = new BrowserMultiFormatReader(hints);
    
    void startScanning();
    
    return () => {
      if (isScanning) {
        stopScanning();
      }
    };
  }, [startScanning, isScanning]);

  if (hasPermission === false) {
    return (
      <div className="text-center p-6 bg-red-50 rounded-xl">
        <XCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
        <p className="text-red-600 font-medium mb-2">Camera Access Required</p>
        <p className="text-red-600 text-sm">
          Please enable camera permissions in your browser settings to scan barcodes.
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="relative aspect-video bg-black rounded-xl overflow-hidden">
        <video
          ref={videoRef}
          className="w-full h-full object-cover"
          autoPlay
          playsInline
          muted
        />
        
        {isScanning && (
          <>
            <div className="absolute inset-0 pointer-events-none">
              <div className="absolute inset-12 border-2 border-emerald-400 rounded-lg">
                <div className="absolute inset-0 flex items-center">
                  <div className="h-0.5 w-full bg-emerald-400/50 animate-scan" />
                </div>
                <div className="absolute inset-0">
                  <div className="absolute top-0 left-0 w-4 h-4 border-t-2 border-l-2 border-emerald-400" />
                  <div className="absolute top-0 right-0 w-4 h-4 border-t-2 border-r-2 border-emerald-400" />
                  <div className="absolute bottom-0 left-0 w-4 h-4 border-b-2 border-l-2 border-emerald-400" />
                  <div className="absolute bottom-0 right-0 w-4 h-4 border-b-2 border-r-2 border-emerald-400" />
                </div>
              </div>
            </div>

            <div className="absolute bottom-4 left-0 right-0 text-center">
              <div className="inline-block px-4 py-2 bg-black/50 backdrop-blur-sm rounded-lg">
                <p className="text-white text-sm">
                  Hold the barcode steady within the frame
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      <ProductPopup
        isOpen={showPopup}
        onClose={handlePopupClose}
        barcode={scannedBarcode}
      />
    </>
  );
}