import { Smartphone, Scan, ShieldCheck } from 'lucide-react';

export default function HowItWorks() {
  return (
    <section className="py-16 bg-white relative overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-1/2 -left-16 w-32 h-32 bg-emerald-100 rounded-full blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-48 h-48 bg-emerald-50 rounded-full blur-3xl opacity-30" />
      </div>

      <div className="max-w-5xl mx-auto px-8 sm:px-12 lg:px-16 relative">
        <div className="text-center mb-12 md:mb-16">
          <span className="text-gray-900 font-medium">How It Works</span>
          <h2 className="mt-2 text-3xl md:text-4xl font-bold text-gray-900">
            3 easy steps to <span className="text-emerald-600">instantly</span> protect your health
          </h2>
        </div>

        <div className="relative">
          {/* Decorative Background */}
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="w-[1000px] h-[1000px] bg-gradient-to-br from-emerald-50/30 to-transparent rounded-full opacity-30 blur-3xl" />
          </div>

          <div className="relative grid md:grid-cols-3 gap-8 md:gap-12">
            {steps.map((step, index) => (
              <div 
                key={index} 
                className="relative group"
              >
                <div className="flex items-center gap-6 p-8 bg-white rounded-3xl shadow-sm transition-all duration-300 group-hover:shadow-lg">
                  {/* Number and Icon Container */}
                  <div className="relative flex-shrink-0">
                    {/* Large Number */}
                    <div className="absolute -top-8 -left-3 text-9xl font-serif font-bold text-emerald-50 transition-colors duration-300 group-hover:text-emerald-100">
                      {index + 1}
                    </div>
                    
                    {/* Icon Circle */}
                    <div className="relative w-14 h-14 bg-gradient-to-br from-emerald-500 to-emerald-600 rounded-2xl flex items-center justify-center transform rotate-45 transition-all duration-300 group-hover:scale-110 group-hover:shadow-md group-hover:shadow-emerald-100">
                      <div className="transform -rotate-45 text-white">
                        {step.icon}
                      </div>
                      
                      {/* Subtle Glow Effect */}
                      <div className="absolute inset-0 bg-gradient-to-tr from-white/20 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    </div>

                    {/* Number Badge */}
                    <div className="absolute -top-2 -right-2 w-6 h-6 bg-white rounded-full flex items-center justify-center shadow-md">
                      <span className="text-sm font-medium bg-gradient-to-br from-emerald-600 to-emerald-500 bg-clip-text text-transparent">
                        {index + 1}
                      </span>
                    </div>
                  </div>

                  {/* Title */}
                  <h3 className="text-lg font-medium text-gray-800 transition-colors duration-300 group-hover:text-emerald-600">
                    {step.title}
                  </h3>
                </div>

                {/* Connector Line */}
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-6 w-12">
                    <div className="h-px bg-gradient-to-r from-emerald-200 to-transparent" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const steps = [
  {
    icon: <Smartphone className="h-6 w-6" />,
    title: "Fill out the Health Profile"
  },
  {
    icon: <Scan className="h-6 w-6" />,
    title: "Scan Any Food"
  },
  {
    icon: <ShieldCheck className="h-6 w-6" />,
    title: "Get Personalized Results"
  }
];