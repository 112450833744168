import { supabase } from '../lib/supabase';
import type { Database } from '../types/supabase';

type UserProfile = Database['public']['Tables']['user_preferences']['Row'];

export async function getUserProfile(userId: string) {
  const { data, error } = await supabase
    .from('user_preferences')
    .select('*')
    .eq('user_id', userId)
    .single();

  if (error && error.code !== 'PGRST116') { // PGRST116 is "not found"
    throw error;
  }

  return data;
}

export async function createUserProfile(userId: string, profile: Partial<UserProfile>) {
  const { data, error } = await supabase
    .from('user_preferences')
    .insert({
      user_id: userId,
      allergen_alerts: profile.allergen_alerts || [],
      dietary_restrictions: profile.dietary_restrictions || [],
      notification_preferences: profile.notification_preferences || { email: true, push: false }
    })
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
}

export async function updateUserProfile(userId: string, profile: Partial<UserProfile>) {
  const { data, error } = await supabase
    .from('user_preferences')
    .update(profile)
    .eq('user_id', userId)
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
}

// Define the HealthProfile type to match your database schema
export interface HealthProfile {
  id: string;
  user_id: string;
  relationship: string;
  name: string;
  age: number;
  allergen_alerts: string[];
  dietary_restrictions: string[];
  health_concerns: string[];
  fitness_goals: string[];
  activity_level: 'low' | 'moderate' | 'high' | null;
  created_at: string;
  updated_at: string;
}

export async function getChildProfiles(userId: string): Promise<HealthProfile[]> {
  // Using any here temporarily until you update your database types
  const { data, error } = await supabase
    .from('health_profiles')
    .select('*')
    .eq('user_id', userId) as { data: HealthProfile[] | null, error: any };

  if (error) {
    console.error('Error fetching health profiles:', error);
    throw error;
  }

  return data || [];
}

// Add a function to create health profiles
export async function createChildProfile(userId: string, profileInfo: {
  relationship: string;
  name: string;
  age: number;
  activity_level: 'low' | 'moderate' | 'high';
  dietary_restrictions: string[];
  health_concerns: string[];
  fitness_goals: string[];
  allergen_alerts?: string[];
}) {
  const { data, error } = await supabase
    .from('health_profiles')
    .insert({
      user_id: userId,
      relationship: profileInfo.relationship,
      name: profileInfo.name,
      age: profileInfo.age,
      activity_level: profileInfo.activity_level,
      dietary_restrictions: profileInfo.dietary_restrictions,
      health_concerns: profileInfo.health_concerns,
      fitness_goals: profileInfo.fitness_goals,
      allergen_alerts: profileInfo.allergen_alerts || [],
    })
    .select()
    .single();

  if (error) {
    console.error('Error creating health profile:', error);
    throw error;
  }

  return data;
}

// Add this new function
export async function updateChildProfile(profileId: string, updates: Partial<Omit<HealthProfile, 'id' | 'user_id' | 'created_at' | 'updated_at'>>) {
  const { data, error } = await supabase
    .from('health_profiles')
    .update(updates)
    .eq('id', profileId)
    .select()
    .single();

  if (error) {
    console.error('Error updating health profile:', error);
    throw error;
  }

  return data;
}

// Add deleteChildProfile function
export async function deleteChildProfile(profileId: string) {
  const { error } = await supabase
    .from('health_profiles')
    .delete()
    .eq('id', profileId);

  if (error) {
    console.error('Error deleting health profile:', error);
    throw error;
  }
} 