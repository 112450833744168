import { useRef, useEffect, useState } from 'react';
import { Star } from 'lucide-react';

const testimonials = [
  {
    name: "Lisa Martinez",
    title: "Registered Dietitian",
    quote: "I recommend this app to all my clients. Whether they're following a **specific diet**, trying to **avoid processed foods**, or just want to eat cleaner, it helps them make informed choices about their nutrition.",
    image: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&q=80&w=2000"
  },
  {
    name: "Sarah Thompson",
    title: "Fitness Enthusiast",
    quote: "I thought I was choosing healthy protein bars until I scanned them with Barcode Bites. Turns out they were packed with **artificial sweeteners** and **hidden sugars**. It's wild how the food industry can hide so much!",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80&w=2000"
  },
  {
    name: "Michael Rodriguez",
    title: "Parent & Health Coach",
    quote: "As both a parent and health coach, this app is invaluable. I can quickly check ingredients for my clients with **dietary restrictions** and ensure my kids' snacks are **actually healthy**, not just marketed that way.",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&q=80&w=2000"
  },
  {
    name: "Emily Chen",
    title: "Concerned Parent",
    quote: "My kids' 'all-natural' snacks were marketed as healthy, but scanning revealed **artificial colors** and **high fructose corn syrup**. I was shocked to learn these 'healthy' snacks had **more sugar than candy**!",
    image: "https://images.unsplash.com/photo-1489424731084-a5d8b219a5bb?auto=format&fit=crop&q=80&w=2000"
  },
  {
    name: "David Wilson",
    title: "Amateur Athlete",
    quote: "Training for my first marathon, I needed clean fuel. This app helped me discover that my go-to energy drinks had **unnecessary additives**. Found better alternatives that actually **support my performance**.",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&q=80&w=2000"
  }
];

// Add this helper function to convert markdown-style bold to HTML
function formatQuote(quote: string) {
  return quote.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
}

export default function Testimonials() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const cardWidth = container.querySelector('.testimonial-card')?.clientWidth || 0;
      const gap = 24; // 6 * 4 (gap-6 class)
      const scrollAmount = cardWidth + gap;

      let newIndex = direction === 'right' ? currentIndex + 1 : currentIndex - 1;

      // Handle circular scrolling
      if (newIndex >= testimonials.length) {
        newIndex = 0;
        container.scrollTo({ left: 0, behavior: 'smooth' });
      } else if (newIndex < 0) {
        newIndex = testimonials.length - 1;
        container.scrollTo({ left: container.scrollWidth, behavior: 'smooth' });
      } else {
        container.scrollBy({ left: direction === 'right' ? scrollAmount : -scrollAmount, behavior: 'smooth' });
      }

      setCurrentIndex(newIndex);
    }
  };

  // Handle scroll snap end
  useEffect(() => {
    const container = scrollRef.current;
    if (!container) return;

    const handleScroll = () => {
      const cardWidth = container.querySelector('.testimonial-card')?.clientWidth || 0;
      const gap = 24;
      const scrollPosition = container.scrollLeft;
      const newIndex = Math.round(scrollPosition / (cardWidth + gap));
      setCurrentIndex(newIndex);
    };

    container.addEventListener('scrollend', handleScroll);
    return () => container.removeEventListener('scrollend', handleScroll);
  }, []);

  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 w-64 h-64 bg-red-50 rounded-full blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-64 h-64 bg-amber-50 rounded-full blur-3xl opacity-30" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-16">
          <span className="text-gray-900 font-medium">Reviews</span>
          <h2 className="mt-2 text-3xl md:text-4xl font-bold text-gray-900">
            Built by <span className="text-emerald-600">Nutritionists</span>, Backed by <span className="text-emerald-600">Science</span>, Loved by <span className="text-emerald-600">Foodies</span>
          </h2>
        </div>

        {/* Scroll Container */}
        <div className="relative">
          {/* Scroll Buttons */}
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
          >
            <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
          >
            <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>

          {/* Scrollable Container */}
          <div 
            ref={scrollRef}
            className="flex overflow-x-auto gap-6 pb-4 snap-x snap-mandatory hide-scrollbar"
          >
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                {...testimonial}
              />
            ))}
          </div>

          {/* Dots Navigation */}
          <div className="flex justify-center gap-2 mt-8">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  if (scrollRef.current) {
                    const cardWidth = scrollRef.current.querySelector('.testimonial-card')?.clientWidth || 0;
                    const gap = 24;
                    scrollRef.current.scrollTo({
                      left: index * (cardWidth + gap),
                      behavior: 'smooth'
                    });
                    setCurrentIndex(index);
                  }
                }}
                className={`w-2 h-2 rounded-full transition-all ${
                  index === currentIndex
                    ? 'bg-emerald-600 w-4'
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function TestimonialCard({
  name,
  title,
  quote,
  image
}: {
  name: string;
  title: string;
  quote: string;
  image: string;
}) {
  return (
    <div className="testimonial-card flex-shrink-0 w-full md:w-[400px] snap-center">
      <div className="bg-white/90 backdrop-blur-sm rounded-xl p-8 h-full transform hover:scale-105 transition-transform">
        <div className="flex gap-1 mb-4">
          {[...Array(5)].map((_, i) => (
            <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
          ))}
        </div>

        <blockquote 
          className="text-lg text-gray-700 mb-4"
          dangerouslySetInnerHTML={{ __html: `"${formatQuote(quote)}"` }}
        />

        <div className="flex items-center gap-4">
          <img
            src={image}
            alt={name}
            className="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <div className="font-medium text-gray-900">{name}</div>
            <div className="text-sm text-gray-600">{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
}