import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  type?: string;
  name?: string;
  path?: string;
}

export default function SEO({ 
  title = "BarcodeBites - Discover What's In Your Food",
  description = "Scan food barcodes to get personalized recommendations based on your child's unique health profile. Make confident food choices for your family.",
  keywords = "food scanner, barcode scanner, children's health, food safety, allergen detection, nutrition app",
  type = "website",
  name = "Barcode Bites",
  path = ""
}: SEOProps) {
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Barcode Bites",
    "url": "https://barcodebites.com",
    "logo": "https://barcodebites.com/logo.png",
    "sameAs": [
      "https://twitter.com/barcodebites",
      "https://facebook.com/barcodebites"
      // Add your social media URLs
    ]
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={name} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      
      {/* Add structured data */}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      
      {/* Add canonical URL */}
      <link rel="canonical" href={`https://barcodebites.com${path}`} />
      
      {/* Add Open Graph image tags */}
      <meta property="og:image" content="https://barcodebites.com/og-image.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      
      {/* Add Twitter image */}
      <meta name="twitter:image" content="https://barcodebites.com/twitter-card.jpg" />
    </Helmet>
  );
} 