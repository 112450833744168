interface FoodTag {
  label: string;
  value: string;
  position: {
    top: number;
    left: number;
  };
}

interface Warning {
  type: 'alert' | 'info';
  title: string;
  description: string;
  color: string;
}

interface FoodItem {
  name: string;
  description: string;
  image: string;
  tags: FoodTag[];
  warnings: Warning[];
}

export const foodItems: FoodItem[] = [
  {
    name: "PRIME Hydration",
    description: "Popular sports drink marketed to kids",
    image: "/prime.png",
    tags: [
      {
        label: "Sugar Content",
        value: "28g per bottle",
        position: { top: 20, left: 20 }
      },
      {
        label: "Artificial Colors",
        value: "Yellow 5, Blue 1",
        position: { top: 70, left: 80 }
      }
    ],
    warnings: [
      {
        type: "alert",
        title: "High Sugar Content",
        description: "Contains as much sugar as a regular soda",
        color: "text-red-400"
      },
      {
        type: "info",
        title: "Artificial Colors",
        description: "Contains synthetic dyes linked to hyperactivity",
        color: "text-yellow-400"
      }
    ]
  },
  {
    name: "Lunchly Meal Kit",
    description: "Pre-packaged lunch marketed as nutritious",
    image: "/lunchly.png",
    tags: [
      {
        label: "Added Sugar",
        value: "13g per serving",
        position: { top: 25, left: 80 }
      },
      {
        label: "Sodium",
        value: "High content",
        position: { top: 75, left: 20 }
      }
    ],
    warnings: [
      {
        type: "alert",
        title: "Processed Ingredients",
        description: "Contains highly processed meats and cheeses",
        color: "text-orange-400"
      },
      {
        type: "info",
        title: "Added Sugars",
        description: "13g of added sugars per serving",
        color: "text-red-400"
      }
    ]
  },
  {
    name: "Veggie Straws",
    description: "Vegetable-flavored snack sticks",
    image: "/veggiestraws.png",
    tags: [
      {
        label: "Main Ingredient",
        value: "Potato Starch",
        position: { top: 20, left: 20 }
      },
      {
        label: "Vegetable Content",
        value: "Less than 1%",
        position: { top: 80, left: 75 }
      }
    ],
    warnings: [
      {
        type: "alert",
        title: "Misleading Marketing",
        description: "Contains minimal real vegetable content",
        color: "text-red-400"
      },
      {
        type: "info",
        title: "Processed Ingredients",
        description: "Primarily made from potato starch and oils",
        color: "text-yellow-400"
      }
    ]
  }
];

export const features = [
  'Instant allergen alerts',
  'Artificial ingredient detection',
  'Personalized health recommendations'
]; 